import { UserApi } from 'mik-common/src/api-autogen/globalmenu/api/user-api'
import { UserInfoModel, UserInfoModelApiResult } from 'mik-common/src/api-autogen/globalmenu'
import { defineStore } from 'pinia'
import { AxiosResponse } from 'axios'
// import { Cookies } from 'quasar'
import VueCookies from 'vue-cookies'
import { installOptions } from '@gnb/modules/menus/main'
const apiUser = new UserApi()
export const useUserInfoStore = defineStore('userInfo', {
  persist: [
    {
      paths: ['userInfo', 'gnbStyle'],
      storage: sessionStorage
    }
  ],
  state: (): IUserInfo => {
    return {
      userInfo: {} as UserInfoModel,
      isLoading: false,
      gnbStyle: ''
    }
  },
  getters: {
    getUserInfo: (state): UserInfoModel => {
      return state.userInfo
    },
    getDisplayName: (state): string => {
      //@ts-ignore
      const langCode = VueCookies.get('GWP_LANGUAGE_CODE') ?? 'ko'
      let userName = ''

      if (state.userInfo.multiLangNames) {
        userName = state.userInfo.multiLangNames[langCode] ?? state.userInfo.userName
      } else {
        userName = state.userInfo.userName ?? ''
      }

      return `${userName} / ${state.userInfo.deptName ?? ''}`
    }
  },
  actions: {
    async userInfoInit(isForce: boolean = false) {
      if (!this.isLoading) {
        this.isLoading = true
        if (this.userInfo.userID == null || isForce || this.checkGnbStyle()) {
          //@ts-ignore
          const langCode = VueCookies.get('GWP_LANGUAGE_CODE') ?? 'ko'

          const response: AxiosResponse<UserInfoModelApiResult> = await apiUser.v1UserInfoGet(langCode, {
            withCredentials: true,
            baseURL: installOptions.baseUrl
          })

          if (response.data.success) {
            this.userInfo = {}
            Object.assign(this.userInfo, response.data.data)
            this.setGnbStyle()
            this.setLastUpdated()

            this.isLoading = false
          } else {
            this.isLoading = false
          }
        }
        this.isLoading = false
      }
    },

    setActivatedTenantID(tenantID: string) {
      if (this.userInfo) {
        this.userInfo.activatedTenantId = tenantID
      }
    },
    logOut() {
      if (this.userInfo) {
        this.userInfo.userID = null
      }
    },
    checkGnbStyle() {
      const gnbCookie = this.getGnbStyle()
      return this.gnbStyle != JSON.stringify(gnbCookie)
    },
    getGnbStyle() {
      //@ts-ignore
      return VueCookies.get('GWP_GNB_STYLEID') as GnbStyle
    },
    setGnbStyle() {
      //쿠키와 store에 저장
      const obj: GnbStyle = {
        headerStyleId: this.userInfo.headerStyleId ?? '',
        widthStyleId: this.userInfo.widthStyleId ?? '',
        menuStyleId: this.userInfo.menuStyleId ?? ''
      }
      this.gnbStyle = JSON.stringify(obj) // store에 저장
      //@ts-ignore
      VueCookies.set('GWP_GNB_STYLEID', JSON.stringify(obj), null, '/', import.meta.env.V_COOKIE_DOMAIN)
    },
    setLocation() {
      const code = this.userInfo.locationCode
      //@ts-ignore
      VueCookies.set('GWP_LOCATION_ID', code, null, '/', import.meta.env.V_COOKIE_DOMAIN)
    },
    setLastUpdated() {
      const code = this.userInfo.lastUpdated
      //@ts-ignore
      VueCookies.set('GWP_LAST_UPDATED', code, null, '/', import.meta.env.V_COOKIE_DOMAIN)
    }
  }
})
export interface GnbStyle {
  headerStyleId: string
  widthStyleId: string
  menuStyleId: string
}
export interface IUserInfo {
  userInfo: UserInfoModel
  isLoading: boolean
  gnbStyle: string
}
